<template>
  <div class="bg_view">
    <div v-for="(item, index) in dataList" :key="item.key" class="flex_view" :style="`${item.type == 3 ? 'flex-direction: column' : ''}`">
      <div class="title_tx" :style="`${item.type != 3 ? `width:${item.type == 1 ? 80 : 140}px` : ''}`">
        {{ item.name }}
      </div>
      <div class="content_tx" :style="`${item.type != 3 ? `width:calc(100% - ${item.type == 1 ? 80 : 140}px - ${index < 4 ? 100 : 0}px)` : 'margin-top: 10px;'}`">
        {{ userInfo[item.key] || '-' }}
      </div>
    </div>
    <div class="user_img">
      <el-image style="width: 100%;height: 100%;" :src="userInfo.avatar"></el-image>
    </div>
  </div>
</template>

<script>
import API from '@/common/axios';
export default {
  data() {
    return {
      userInfo: {},
      dataList: [{
        name: "微信昵称",
        key: "nickname",
        type: 1
      }, {
        name: "性别",
        key: "gender",
        type: 1
      }, {
        name: "年龄",
        key: "age",
        type: 1
      }, {
        name: "地区",
        key: "region",
        type: 1
      }, {
        name: "发型",
        key: "hairstyle",
        type: 1
      }, {
        name: "必要 Uid",
        key: "uid",
        type: 2
      }, {
        name: "微信 UnionId",
        key: "unionId",
        type: 2
      }, {
        name: "微信 ExternalUserId",
        key: "externalUserId",
        type: 2
      }, {
        name: "微信 OpenId",
        key: "openId",
        type: 2
      }, {
        name: "个人信息",
        key: "personal_info",
        type: 3
      }, {
        name: "家庭成员信息",
        key: "family_member_info",
        type: 3
      }]
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      API({
        url: "http://aiapi.biyao.com/llm/v1/model/user/info",
        data: {
          externalUserId: this.$parent.selectUserInfo.externalUserid
        }
      }).then(res => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.getUserOpenId();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getUserOpenId() {
      API({
        url: "/pendingreply/getUserInfo.do",
        data: { unionId: this.userInfo.unionId },
      }).then(res => {
        if (res.success == 0) {
          return;
        }
        this.userInfo.openId = res.data.openId || "";
        this.userInfo = {...this.userInfo};
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.bg_view {
  padding: 20px;
  position: relative;
}

.title_tx {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height: 20px;
}

.content_tx {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  word-break: break-all;
  text-align: justify;
}

.flex_view {
  display: flex;
  padding: 8px 0;
}

.user_img {
  position: absolute;
  right: 10px;
  top: 30px;
  font-size: 0;
  width: 100px;
  height: 100px;
}
</style>